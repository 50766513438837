import BeepLogo from '../Constants/Images/BeepLogo.svg'
import { NavLink } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import { Button } from "@mui/material"
import { useNavigate } from 'react-router-dom'

export default function Header(props:any){
    const {navid} = props
    const navigate = useNavigate()
    const logout = () =>{
        sessionStorage.removeItem('token');
        navigate("/")
    }
    return(<div>
        <div id="Header" style={{position:"absolute",height:"30%",width:"100%",background: "linear-gradient(180deg, #8439FF 26.92%, #5235E6 40.39%, rgba(97, 141, 255, 0.07) 100%)",filter: "blur(50px)"}}>
        </div>
        <div style={{position:"absolute", margin:"2%", width:"95%", textAlign:"center", }}>
            <div style={{float:"left"}}>
                <img src={BeepLogo} />
            </div>
            <div style={{display:"inline", position:"absolute",width:"12%", left:"40%",fontFamily:"Poppins", fontSize:16, color:"#FFFFFF"}}>
                {navid == 1?(<NavLink to="/dashboard" style={{float:"left", fontWeight:600, borderBottomWidth:3, borderStyle:"solid",paddingBottom:3}}>Dashboard</NavLink>):(<NavLink to="/dashboard" style={{float:"left"}}>Dashboard</NavLink>)}
                {navid == 2?(<NavLink to="/training" style={{float:"right", fontWeight:600, borderBottomWidth:3, borderStyle:"solid",paddingBottom:3}}>Modules</NavLink>):(<NavLink to="/training" style={{float:"right"}}>Modules</NavLink>)}
            </div>
            <div style={{float:"right"}}>
                <Button onClick={logout}><LogoutIcon style={{color:"#FFFFFF"}}/></Button>
            </div>
        </div>
        <div style={{ position:"absolute",height:2, marginTop:"5%",marginLeft: "3%", marginRight: "3%",width:"94%", background: "#D6D5CB"}}></div>
    </div>)
}