import { useEffect, useState, useTransition } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import AxiosInstance from "../Constants/Config/AxiosInstance";
import ReactPlayer from "react-player";
import { DisabledByDefault } from "@mui/icons-material";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import PageHeading from "../Components/pageHeading";
import Header from "../Components/Header";
import { Paper } from "@mui/material";

export default function Course(){
    const {id} = useParams()
    const [courseData,setCourseData]:any = useState({})
    const [chapters,setChapters]:any = useState([])
    const [chaptersCompleted,setChaptersCompleted]:any = useState([])
    const [runIndex,setRunIndex]:any = useState(0)
    const [render,setRender] = useState(false)
    const [next,setNext] = useState(false)
    const navigate =useNavigate()

    useEffect(() => {
        setRender(false)
        AxiosInstance()
        .get(`/intern-panel/trainings/${id}`)
        .then(({data})=>{
          console.log(data)
          setCourseData(data.course)
          setChaptersCompleted(data.chapter_completed)
          setChapters(Object.keys(data.course.chapters))
          setRunIndex(data.chapter_completed.length)
          setRender(true)
        })
      }, [next]);

      const onEnd = () =>{
          console.log('Video Ended')
        AxiosInstance()
        .post(`/intern-panel/trainings`,{
            courseId:id,
            chapter:chapters[runIndex]
        }).then((data)=>{
            if(runIndex === (chapters.length-1)){
                navigate('/training')
            }
            else{
                setNext(!next)
            }
        })
      }

    return(<div>
        <Header 
            navid={2}/>
        <div style={{ position: "absolute", width: "90%", marginTop: "8%", marginLeft: "5%", marginRight: "5%"}}>
        <Paper elevation={5} style={{padding:10}}>
        
        {
            render?(<div className="w-full h-screen bg-slate-100">
            <PageHeading heading={`${courseData.moduleName} - ${chapters[runIndex]}`} />
            
            <div className="w-full inline-block">
                <ReactPlayer
                className="mx-auto shadow px-2 py-2 my-5  bg-white "
                    url={courseData['chapters'][chapters[runIndex]]}
                    onEnded={onEnd}
                    loop={false}
                    playing= {true}
                />
            </div>
            </div>
            ):(<></>)
        }
        </Paper>
        </div>
        
        </div>)
}