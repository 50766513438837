import { StrictMode } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './Pages/Login'
import Training from './Pages/Training'
import Course from "./Pages/Course";
import DashboardNew from "./Pages/Dashboard_new";
import PersistentDrawerLeft from "./Components/Navigation";
import ManagerDashboard from "./Pages/ManagerDashboard";
import ManagerLogin from "./Pages/ManagerLogin";
  
function App() {
  return (
    <StrictMode>

        <BrowserRouter>
        <div >
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<DashboardNew />} />
            <Route path="/manager/dashboard" element={<ManagerDashboard/>}/>
            <Route path="/manager/login" element={<ManagerLogin/>}/>
            <Route path="/training" element={<Training />} />
            <Route path="/course/:id" element={<Course />} />
            <Route path="/dashboardnew" element={<DashboardNew />} />
          </Routes>
          </div>
        </BrowserRouter>
        
    </StrictMode>

  )
}

export default App
