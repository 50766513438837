import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Beep from '../Constants/Images/BeepLogo.png'
import FrontCard from '../Constants/Images/Front_card.svg'
// import { Visibility, VisibilityOff } from "@mui/icons-material";
import AxiosInstance from "../Constants/Config/AxiosInstance";
import { WindowSharp } from "@mui/icons-material";
// import 'material-react-toastify/dist/ReactToastify.css';

export default function Login() {

    const [password, setPassword] = useState("")
    const [username, setUsername] = useState("")
    const [otpSent, setOTPSent] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [errorText, setErrorText] = useState("")
    const [passwordMissing, setPasswordMissing] = useState(false)
    const navigate = useNavigate()

    // useEffect(() => {
        
    // }, [])
    
    const setValue = (value: string, type: string) => {
        if (type === 'username') {
            setErrorText("")
            setUsername(value)
        }
        else {
            setPassword(value)
            setPasswordMissing(false)
        }
    }



    const handleSubmit = async(event:any) => {
        event.preventDefault()
        if (otpSent) {
            if (password.length < 1) {
                setPasswordMissing(true)
            }
            else{
                AxiosInstance()
                    .post('/intern-panel/verifyOTP', {
                        number: `+91${username}`,
                        otp: password
                    })
                    .then(({ data }: any) => {
                        sessionStorage.setItem('token', data.token)
                        // navigate('/dashboard')
                        // @ts-ignore
                        window.location = "/dashboard";
                    })
                    .catch((err: any) => {
                        setErrorText(err.message)
                    })
            }
        }
        else {
            console.log(username, typeof(username))
            if (!/^[0-9]+$/.test(username) || username.length !== 10) {
                setErrorText("Invalid Number")
            }
            else {
                
                AxiosInstance()
                    .post('/intern-panel/sendOtp', {
                        number: `+91${username}`
                    })
                    .then(({ data }: any) => {
                        setOTPSent(true)
                    })
                    .catch((err: any) => {
                        console.log("inside error", err)
                        setErrorText(err.message)
                    })
            }
        }


    }

    const clickShowPassword = () => {
        setShowPassword(!showPassword)
    }


    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <div className="h-screen bg-slate-100 m-auto">
            <div className="flex m-auto w-9/12 shadow" style={{ "position": "absolute", "top": "50%",  "left": "50%",  "transform": "translate(-50%, -50%)"}}> {/*page*/}
            <div className="flex "> {/*stack*/}
            <div style={{ background:"#7B61FF", alignContent:"center"}} className="w-4/6 rounded-tl rounded-bl">
                <img src={FrontCard} />
            </div>
            <div style={{}} className="bg-white w-2/6/2 px-5 rounded-tr rounded-br">
                <div  style={{alignItems:"center"}} className="py-8"> {/*stack*/}
                    <img src={Beep}  className="mx-auto w-1/6" />
                    <form > {/*form*/}
                        <div className="flex flex-col w-4/6 mx-auto pt-12"> {/*stack*/}
                            {
                                otpSent ? (
                                    <form > {/*form*/}
                                        
                                        <input
                                            required
                                            id="password_input"
                                            placeholder="OTP"
                                            type={showPassword ? "text" : "password"}
                                            className='border-2 border-stone-100 rounded active:border-2 active:border-stone-700 px-2 py-2 my-2 w-full'
                                            // error={passwordMissing}
                                            // endAdornment={
                                            //     <InputAdornment position="end">
                                            //         <IconButton
                                            //             aria-label="toggle password visibility"
                                            //             onClick={clickShowPassword}
                                            //             onMouseDown={handleMouseDownPassword}
                                            //             edge="end"
                                            //         >
                                            //             {showPassword ? <VisibilityOff /> : <Visibility />}
                                            //         </IconButton>
                                            //     </InputAdornment>
                                            // }
                                            onChange={(e) => { setValue(e.target.value, 'password') }}
                                        />
                                    </form>
                                ) : (<input type='text'
                                    required
                                    id="username_input"
                                    className='border-2 border-stone-100 rounded active:border-2 active:border-stone-700 px-2 py-2 my-2'
                                    placeholder="Intern Phone Number"
                                    // variant="outlined"
                                    // maxRows={1}
                                    // inputProps={{
                                    //     inputMode: 'numeric',
                                    //     minLength: 10,
                                    //     maxLength: 10,
                                    //     pattern: '[0-9]*'
                                    // }}
                                    // helperText={errorText}
                                    // error={errorText.length === 0 ? false : true}
                                    onChange={(e) => { setValue(e.target.value, 'username') }}
                                />)
                            }


                            <button  onClick={handleSubmit}
                                className="shadow-lg px-2 py-2 bg-yellow-300 rounded"
                            >{otpSent ? (<>Login</>) : (<>Request OTP</>)}</button>
                            {
                                errorText? <span className="text-red">{errorText}</span>: " "
                            }
                        </div> {/*stack*/}
                    </form>
                </div> {/*stack*/}
            </div>
            </div> {/*stack*/}
            <div/> {/*page*/}
            <div />
        </div>
        </div>
    )
}

