import React from 'react';

const PageHeading = ({heading}:any) => ( 
    <div className="py-1 bg-white">
        <h2 className='px-2 mx-4 py-2 my-2 font-bold text-lg border-l-4 border-yellow-400'>
                {heading}
            </h2>
            
        </div>
     );
export default PageHeading;