import { Button, Paper, TextField, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import Header from "../Components/Header";
import CampusRef from "../Constants/Images/CampusRef.svg";
import TotalRef from "../Constants/Images/TotalRef.svg";
import BeepMoments from "../Constants/Images/BeepMom.svg";
import TotalEarn from "../Constants/Images/TotalEarn.svg";
import AxiosInstance from "../Constants/Config/AxiosInstance";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UploadIcon from "@mui/icons-material/Upload";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

export default function DashboardNew() {
  const [intern, setIntern]: any = useState({});
  const [topPerformers, setTopPerformers] = useState([]);
  const [latestActivity, setLatestActivity] = useState([]);
  const [tabvalue, setTabValue] = useState(0);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [refferalCode, setRefferalCode]: any = useState(" ");
  const [description, setDescription]: any = useState("");
  const [uploadTask, setUploadTask]: any = useState({
    taskname: "",
    _id: "",
    links: [],
  });
  const [refresh, setRefresh]: any = useState(false);
  const [tasks, setTasks]: any = useState({
    pending: [],
    unverified: [],
    verified: [],
  });
  const [programDetails, setProgramDetails] = useState({
    target: 1,
    paid: false,
  });
  const [rank, setRank] = useState(0);
  const [managerDetails, setManagerDetails] = useState({
    managerName: "",
    number: "",
  });
  const [duration, setDuration] = useState(31);
  const [buttondisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    AxiosInstance()
      .get("/intern-panel/dashboard")
      .then(({ data }) => {
        setTopPerformers(data.topInterns);
        setLatestActivity(data.latestactivities);
        setIntern(data.internData);
        setTasks(data.taskstatus);
        setRefferalCode(data.refferalCode);
        setRank(data.rank);
        setProgramDetails(data.programDetails);
        setManagerDetails(data.managerDetails);
        setUploadOpen(false);
        setDuration(data.days_left);
        setButtonDisabled(false);
      });
  }, [refresh]);

  const handleTabValueChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      `Use the code ${refferalCode} to sign up to Beep and get 100 coins!\nhttps://link.eventbeep.com/app`
    );
    toast.success("Referral Code Copied");
  };

  const uploadTaskImagesPopup = (task: any) => {
    setUploadOpen(true);
    setDescription("");
    setUploadTask({
      taskname: task.taskname,
      _id: task._id,
      description: task.description,
      links: task.links,
    });
  };

  const submitTask = (event: any) => {
    console.log(event.target.disabled);
    event.target.disabled = true;
    setButtonDisabled(true);
    const reqFormData = new FormData();
    reqFormData.append("task_id", uploadTask._id);
    reqFormData.append("description", description);
    reqFormData.append("upload", uploadTask.image);
    AxiosInstance()
      .post("/intern-panel/submitTask", reqFormData)
      .then(({ data }) => {
        setRefresh(!refresh);
        setButtonDisabled(false);
        event.target.disabled = false;
      })
      .catch((err: any) => {
        setButtonDisabled(false);
        event.target.disabled = false;
      });
  };

  const onImageChange = (event: any) => {
    console.log("In Image Change Function");
    const reader = new FileReader();

    // Read the contents of Image File.
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function (e: any) {
      // Initiate the JavaScript Image object.
      const image: any = new Image();

      // Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      // Validate the File Height and Width.
      image.onload = function () {
        const { height, width } = this;
        console.log(height, width);
        if (event.target.files && event.target.files[0]) {
          setUploadTask((prev: any) => ({
            ...prev,
            imageUrl: URL.createObjectURL(event.target.files[0]),
          }));
        }
      };
    };
  };

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const uploadImage = (e: any) => {
    const { name, files } = e.target;
    setUploadTask((prev: any) => ({
      ...prev,
      [name]: files[0],
    }));
    onImageChange(e);
  };
  return (
    <div>
      <Header navid={1} />
      <div
        style={{
          position: "absolute",
          width: "90%",
          marginTop: "8%",
          marginLeft: "5%",
          marginRight: "5%",
        }}
      >
        <div
          style={{
            width: "65%",
            display: "inline-block",
            padding: 4,
            verticalAlign: "top",
          }}
        >
          <Paper elevation={5} style={{ padding: 2, borderRadius: 16 }}>
            <div
              style={{
                marginLeft: 5,
                marginRight: 5,
                marginBottom: 5,
                marginTop: 30,
                height: "10%",
                textAlign: "center",
              }}
            >
              <div
                style={{ width: "23%", display: "inline-block", padding: 5 }}
              >
                <Paper
                  elevation={5}
                  style={{
                    background: "#F6F6F6",
                    height: "inherit",
                    borderRadius: 16,
                  }}
                >
                  <div style={{ padding: 4 }}>
                    <img
                      style={{ position: "relative", top: -22, left: 20 }}
                      src={TotalRef}
                    />
                    <p style={{ fontSize: 36, fontWeight: 700 }}>
                      {intern.refferals}
                    </p>
                    <p style={{ fontSize: 16, fontWeight: 500 }}>
                      Total Refferals
                    </p>
                    <p style={{ fontSize: 12, fontWeight: 400 }}>
                      10 {programDetails.paid ? <>{"\u20B9"}</> : <>Pts</>}
                      /College
                    </p>
                  </div>
                </Paper>
              </div>
              <div
                style={{ width: "23%", display: "inline-block", padding: 5 }}
              >
                <Paper
                  elevation={5}
                  style={{
                    background: "#F6F6F6",
                    height: "inherit",
                    borderRadius: 16,
                  }}
                >
                  <div style={{ padding: 4 }}>
                    <img
                      style={{ position: "relative", top: -22, left: 20 }}
                      src={CampusRef}
                    />
                    <p style={{ fontSize: 36, fontWeight: 700 }}>
                      {intern.campusRefferals}
                    </p>
                    <p style={{ fontSize: 16, fontWeight: 500 }}>
                      Campus Referrals
                    </p>
                    <p style={{ fontSize: 12, fontWeight: 400 }}>
                      15 {programDetails.paid ? <>{"\u20B9"}</> : <>Pts</>}
                      /College
                    </p>
                  </div>
                </Paper>
              </div>
              <div
                style={{
                  width: "23%",
                  display: "inline-block",
                  padding: 5,
                  verticalAlign: "top",
                }}
              >
                <Paper
                  elevation={5}
                  style={{
                    background: "#F6F6F6",
                    height: "inherit",
                    borderRadius: 16,
                  }}
                >
                  <div style={{ padding: 4 }}>
                    <img
                      style={{ position: "relative", top: -22, left: 20 }}
                      src={BeepMoments}
                    />
                    <p style={{ fontSize: 36, fontWeight: 700 }}>
                      {intern.beepmoments}
                    </p>
                    <p style={{ fontSize: 16, fontWeight: 500 }}>
                      User Posted Beeps
                    </p>
                    <p style={{ fontSize: 12, fontWeight: 400 }}>
                      10 {programDetails.paid ? <>{"\u20B9"}</> : <>Pts</>}
                      /FirstBeep
                    </p>
                  </div>
                </Paper>
              </div>
              <div
                style={{ width: "23%", display: "inline-block", padding: 5 }}
              >
                <Paper
                  elevation={5}
                  style={{
                    background: "#F6F6F6",
                    height: "inherit",
                    borderRadius: 16,
                  }}
                >
                  <div style={{ padding: 4 }}>
                    <img
                      style={{ position: "relative", top: -22, left: 20 }}
                      src={TotalEarn}
                    />
                    <p style={{ fontSize: 36, fontWeight: 700 }}>
                      {programDetails.paid ? <>{"\u20B9"}</> : null}{" "}
                      {intern.stipend}
                    </p>
                    <p style={{ fontSize: 16, fontWeight: 500 }}>
                      Total {programDetails.paid ? <>Amt</> : <>Pts</>}{" "}
                      Collected
                    </p>
                    <p style={{ fontSize: 12, fontWeight: 400 }}>
                      Target {programDetails.target}
                    </p>
                  </div>
                </Paper>
              </div>
            </div>
            <div
              style={{
                marginLeft: 20,
                marginRight: 20,
                marginBottom: 10,
                marginTop: 20,
                height: "10%",
              }}
            >
              <Paper
                elevation={5}
                style={{ background: "#F6F6F6", borderRadius: 16, padding: 5 }}
              >
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: 700,
                    padding: 5,
                    marginLeft: 10,
                  }}
                >
                  My Details
                </p>
                <div style={{ alignItems: "center" }}>
                  <Paper
                    elevation={5}
                    style={{
                      padding: 20,
                      margin: 10,
                      backgroundColor: "rgba(45, 43, 44, 1)",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        width: "70%",
                        marginLeft: 20,
                      }}
                    >
                      <div style={{ fontSize: 14, color: "#FFFFFF" }}>
                        Leaderboard Rank
                      </div>
                      <div style={{ fontSize: 24, color: "#FFFFFF" }}>
                        {rank}
                      </div>
                      <div
                        style={{
                          fontSize: 14,
                          marginBottom: 5,
                          color: "#FFFFFF",
                        }}
                      >
                        My Progress:{" "}
                        {(
                          (intern.stipend / programDetails.target) *
                          100
                        ).toFixed(1)}{" "}
                        %
                      </div>
                      <BorderLinearProgress
                        style={{ width: "80%" }}
                        variant="determinate"
                        value={(intern.stipend / programDetails.target) * 100}
                      />
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "25%",
                        margin: 5,
                      }}
                    >
                      <div style={{ fontSize: 12, color: "#FFFFFF" }}>
                        Manager's Details
                      </div>
                      <div style={{ fontSize: 20, color: "#FFFFFF" }}>
                        {managerDetails.managerName}
                      </div>
                      <div style={{ fontSize: 20, color: "#FFFFFF" }}>
                        {managerDetails.number}
                      </div>
                      <div style={{ fontSize: 14, color: "#FFFFFF" }}>
                        {duration} Days Left
                      </div>
                    </div>
                  </Paper>
                </div>
                {/* <div style={{ verticalAlign: "baseline", marginTop: 5 }}>
                                <div style={{ display: 'inline-block', width: "85%" }}>
                                    <TextField
                                        style={{ padding: 10 }}
                                        size="small"
                                        id="outlined-read-only-input"
                                        label="Refferal Code"
                                        value={refferalCode}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </div>
                                <div style={{ display: 'inline-block' }}>
                                    <Button
                                        disabled={!refferalCode}
                                        style={{ background: "#16C083", color: "#FFFFFF", padding: 15, margin: 5, borderRadius: 20 }} onClick={copyToClipboard}>Share</Button>
                                </div>
                            </div> */}
                {/* <div style={{ marginLeft: 20, color: "#FF0000" }}>
                                {refferalCode ? (null) : (<>Refferal Code not Found, Create an Account with your number on App</>)}
                            </div> */}
              </Paper>
            </div>
            <div
              style={{
                marginLeft: 20,
                marginRight: 20,
                marginBottom: 10,
                marginTop: 20,
                height: "10%",
              }}
            >
              <Paper
                elevation={5}
                style={{
                  padding: 2,
                  height: "40%",
                  margin: 5,
                  borderRadius: 16,
                }}
              >
                <p style={{ margin: 10, fontWeight: 700 }}>Latest Activities</p>
                <div style={{ margin: 10 }}>
                  <div className={`flex px-2 py-2 `}>
                    {" "}
                    {/* row direction*/}
                    <div>Description</div>
                    <div className="mr-0 ml-auto">Reward</div>
                  </div>
                  {latestActivity.length ? (
                    latestActivity.map((activity: any, index: number) => {
                      return (
                        <div
                          className={`flex px-2 py-2 ${
                            index !== 0 ? "border-t-2" : ""
                          }`}
                        >
                          {" "}
                          {/* row direction*/}
                          <div>
                            {index + 1}. {activity.activity}
                          </div>
                          <div className="mr-0 ml-auto">
                            {activity.reward}{" "}
                            {programDetails.paid ? <>{"\u20B9"}</> : <>Pts</>}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-center">No Records</div>
                  )}
                </div>
              </Paper>
            </div>
          </Paper>
        </div>
        <div style={{ width: "30%", display: "inline-block", padding: 4 }}>
          <Paper
            elevation={5}
            style={{ padding: 2, height: "40%", margin: 5, borderRadius: 16 }}
          >
            <p style={{ margin: 10, fontWeight: 700 }}>Leaderboard</p>
            <div style={{ margin: 10 }}>
              {topPerformers.length ? (
                topPerformers.map((topintern: any, index: number) => {
                  if (rank === index + 1) {
                    return (
                      <div
                        className={`flex px-2 py-2 ${
                          index !== 0 ? "border-t-2" : ""
                        }`}
                        style={{ background: "#7B61FF", color: "#FFFFFF" }}
                      >
                        {" "}
                        {/* row direction*/}
                        <div>
                          {index + 1}. {topintern.internName}
                        </div>
                        <div className="mr-0 ml-auto">{topintern.stipend}</div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className={`flex px-2 py-2 ${
                          index !== 0 ? "border-t-2" : ""
                        }`}
                      >
                        {" "}
                        {/* row direction*/}
                        <div>
                          {index + 1}. {topintern.internName}
                        </div>
                        <div className="mr-0 ml-auto">{topintern.stipend}</div>
                      </div>
                    );
                  }
                })
              ) : (
                <div className="text-center">No Records</div>
              )}
              {topPerformers.length ? (
                rank > 5 ? (
                  <div
                    className={`flex px-2 py-2 ${
                      rank !== 0 ? "border-t-2" : ""
                    }`}
                    style={{ background: "#7B61FF", color: "#FFFFFF" }}
                  >
                    {" "}
                    {/* row direction*/}
                    <div>
                      {rank}. {intern.internName}
                    </div>
                    <div className="mr-0 ml-auto">{intern.stipend}</div>
                  </div>
                ) : null
              ) : null}
            </div>
          </Paper>
          <Paper
            elevation={5}
            style={{
              backgroundColor: "rgba(45, 43, 44, 1)",
              padding: 2,
              height: "40%",
              margin: 5,
              borderRadius: 16,
            }}
          >
            <p
              style={{
                margin: 10,
                color: "rgba(255, 255, 255, 1)",
                fontWeight: 700,
              }}
            >
              TASKS
            </p>
            <div style={{ margin: 10 }}>
              <Tabs
                value={tabvalue}
                onChange={handleTabValueChange}
                centered
                style={{
                  borderRadius: 16,
                  height: 18,
                  backgroundColor: "rgba(116, 116, 116, 1)",
                }}
                sx={{
                  [`& .${tabsClasses.indicator}`]: {
                    background: "#FFFFFF",
                  },
                }}
              >
                <Tab
                  style={{ color: "#FFFFFF", fontSize: 12 }}
                  label="Pending"
                />
                <Tab
                  style={{ color: "#FFFFFF", fontSize: 12 }}
                  label="Unverified"
                />
                <Tab
                  style={{ color: "#FFFFFF", fontSize: 12 }}
                  label="Verified"
                />
              </Tabs>
              {tabvalue === 0 ? (
                <>
                  {tasks.pending.map((pending: any, index: number) => {
                    return (
                      <div
                        style={{ color: "#FFFFFF" }}
                        className={`flex px-2 py-2 ${
                          index !== 0 ? "border-t-2" : ""
                        }`}
                      >
                        {" "}
                        {/* row direction*/}
                        <div>
                          {index + 1}. {pending.taskname} {"("}
                          {pending.reward}
                          {programDetails.paid ? (
                            <>{"\u20B9"}</>
                          ) : (
                            <>Pts</>
                          )}{" "}
                          {")"}
                        </div>
                        <div className="mr-0 ml-auto">
                          <Button
                            onClick={() => {
                              uploadTaskImagesPopup(pending);
                            }}
                          >
                            <RemoveRedEyeIcon />
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
              {tabvalue === 1 ? (
                <>
                  {tasks.unverified.map((pending: any, index: number) => {
                    return (
                      <div
                        style={{ color: "#FFFFFF" }}
                        className={`flex px-2 py-2 ${
                          index !== 0 ? "border-t-2" : ""
                        }`}
                      >
                        {" "}
                        {/* row direction*/}
                        <div>
                          {index + 1}. {pending.taskname}
                          {"("}
                          {pending.reward}
                          {programDetails.paid ? <>{"\u20B9"}</> : <>Pts</>}
                          {")"}
                        </div>
                        <div className="mr-0 ml-auto">
                          <CheckBoxIcon
                            style={{ color: "rgba(237, 156, 35, 1)" }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
              {tabvalue === 2 ? (
                <>
                  {tasks.verified.map((pending: any, index: number) => {
                    return (
                      <div
                        style={{ color: "#FFFFFF" }}
                        className={`flex px-2 py-2 ${
                          index !== 0 ? "border-t-2" : ""
                        }`}
                      >
                        {" "}
                        {/* row direction*/}
                        <div>
                          {index + 1}. {pending.taskname}
                          {"("}
                          {pending.reward}
                          {programDetails.paid ? <>{"\u20B9"}</> : <>Pts</>}
                          {")"}
                        </div>
                        <div className="mr-0 ml-auto">
                          <CheckBoxIcon style={{ color: "#16C083" }} />
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
          </Paper>
        </div>
      </div>
      <Modal
        open={uploadOpen}
        onClose={() => {
          setUploadOpen(false);
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "30%",
            width: "40%",
            background: "#FFFFFF",
            top: "30%",
            height: "50%",
            overflowY: "scroll",
          }}
        >
          <div style={{ margin: 10 }}>
            <div
              style={{
                fontSize: 22,
                fontWeight: 700,
                padding: 5,
                marginLeft: 10,
              }}
            >
              {uploadTask.taskname}
            </div>
            <div
              style={{
                fontSize: 16,
                fontWeight: 400,
                padding: 5,
                marginLeft: 10,
              }}
            >
              Description : {uploadTask.description}
            </div>
            <div
              style={{
                fontSize: 16,
                fontWeight: 400,
                padding: 5,
                marginLeft: 10,
              }}
            >
              Refrence Links:
              {uploadTask.links.map((link: any) => {
                return (
                  <div
                    style={{
                      fontSize: 12,
                      fontWeight: 200,
                      padding: 5,
                      marginLeft: 10,
                    }}
                  >
                    <a href={link}>{link}</a>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                fontSize: 12,
                fontWeight: 400,
                padding: 5,
                marginLeft: 10,
              }}
            >
              Upload Task Image
            </div>
            <div className="flex flex-col">
              <div className="px-4 py-1">
                <input
                  type="file"
                  name="image"
                  className="block form-control w-full h-full w-96 px-4 py-2 text-base font-normal bg-slate-300 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  placeholder="Enter Clubs name"
                  accept="image/*"
                  onChange={uploadImage}
                />
              </div>
            </div>
            {uploadTask.imageUrl && (
              <img
                className="mt-2 h-24 px-5"
                src={uploadTask.imageUrl}
                alt="Image"
              />
            )}
            <div
              style={{
                fontSize: 12,
                fontWeight: 400,
                padding: 5,
                marginLeft: 10,
              }}
            >
              <TextField
                label="Description"
                fullWidth
                onChange={onDescriptionChange}
              />
            </div>
            <div style={{ margin: 10 }}>
              <div style={{ display: "inline-block" }}>
                {buttondisabled ? null : (
                  <Button
                    disabled={!uploadTask.imageUrl || buttondisabled}
                    onClick={(event) => {
                      submitTask(event);
                    }}
                    style={{
                      padding: 5,
                      color: "#FFFFFF",
                      backgroundColor: "#16C083",
                    }}
                  >
                    Upload
                  </Button>
                )}
              </div>
              <div style={{ display: "inline-block" }}>
                <Button
                  onClick={() => {
                    setUploadOpen(false);
                  }}
                  style={{
                    padding: 5,
                    marginLeft: 5,
                    color: "#FFFFFF",
                    backgroundColor: "#2D2B2C",
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
}
