import {
  Button,
  Paper,
  TextField,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import HeaderManager from "../Components/HeaderManager";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AxiosInstance from "../Constants/Config/AxiosInstance";
import ImageIcon from "@mui/icons-material/Image";
import CancelIcon from "@mui/icons-material/Cancel";
import DoneIcon from "@mui/icons-material/Done";
import { DataGrid } from "@mui/x-data-grid";
import CampusRef from "../Constants/Images/CampusRef.svg";
import TotalRef from "../Constants/Images/TotalRef.svg";
import BeepMoments from "../Constants/Images/BeepMom.svg";
import TotalEarn from "../Constants/Images/TotalEarn.svg";

export default function ManagerDashboard() {
  const [tabvalue, setTabValue] = useState(0);
  const [taskRequests, setTaskRequests]: any = useState({
    pending: [],
    approved: [],
    intern: [],
  });
  const [intern, setIntern]: any = useState({});
  const [latestActivity, setLatestActivity] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [detailopen, setDetailOpen] = useState(false);
  const [programDetails, setProgramDetails] = useState({
    target: 1,
    paid: false,
  });
  const [openModel, setOpenModal] = useState(false);
  const [imgsrc, setImagesrc] = useState("");
  const [pending_tasks, set_pending_tasks] = useState([]);
  const [approved_tasks, set_approved_tasks] = useState([]);
  const [declined_tasks, set_declined_tasks] = useState([]);
  const [tasks, setTasks]: any = useState({
    pending: [],
    unverified: [],
    verified: [],
  });

  const [addIntern, setaddIntern] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [new_intern_info, setnew_intern_info]: any = useState({});
  const [selected_intern, set_selected_intern]: any = useState("");

  useEffect(() => {
    setOpenModal(false);
    setDetailOpen(false);
    setaddIntern(false);
    AxiosInstance()
      .get("/intern-panel/manager/dashboard")
      .then(({ data }) => {
        setTaskRequests(data);
        let pending_task_info: any = [];
        let approved_task_info: any = [];
        let declined_tasks_info: any = [];
        for (let index = 0; index < data.pending.length; index++) {
          pending_task_info.push({
            _id: data.pending[index]["_id"],
            internName: data.pending[index].Intern[0].internName,
            taskname: data.pending[index].TaskInfo[0].taskname,
            reward: data.pending[index].TaskInfo[0].reward,
            image: data.pending[index].image,
            description: data.pending[index].TaskInfo[0].description,
          });
        }

        for (let index = 0; index < data.approved.length; index++) {
          approved_task_info.push({
            _id: data.approved[index]["_id"],
            internName: data.approved[index].Intern[0].internName,
            taskname: data.approved[index].TaskInfo[0].taskname,
            reward: data.approved[index].TaskInfo[0].reward,
            image: data.approved[index].image,
            description: data.approved[index].TaskInfo[0].description,
          });
        }

        for (let index = 0; index < data.declined.length; index++) {
          declined_tasks_info.push({
            _id: data.declined[index]["_id"],
            internName: data.declined[index].Intern[0].internName,
            taskname: data.declined[index].TaskInfo[0].taskname,
            reward: data.declined[index].TaskInfo[0].reward,
            image: data.declined[index].image,
            description: data.declined[index].TaskInfo[0].description,
          });
        }
        set_pending_tasks(pending_task_info);
        set_approved_tasks(approved_task_info);
        set_declined_tasks(declined_tasks_info);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [refresh]);

  const handleTabValueChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  const viewImage = (imagesrc: any) => {
    setImagesrc(taskRequests.bitbucketURL + imagesrc);
    setOpenModal(true);
  };

  const updateTaskStatus = (taskid: string, status: boolean) => {
    AxiosInstance()
      .post("/intern-panel/manager/updateTask", {
        taskid: taskid,
        accepted: status,
      })
      .then(({ data }) => {
        setRefresh(!refresh);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const PendingDataGridColumn = [
    {
      field: "internName",
      headerName: "Intern Name",
      width: 150,
    },
    {
      field: "taskname",
      headerName: "Task",
      width: 150,
    },
    {
      field: "reward",
      headerName: "Reward",
      width: 150,
    },
    {
      field: "image",
      headerName: "Image",
      width: 150,
      renderCell: (params: any) => {
        return (
          <Button
            onClick={() => {
              viewImage(params.row.image);
            }}
          >
            <ImageIcon style={{ color: "#000000" }} />
          </Button>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      width: 400,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params: any) => {
        return (
          <div>
            <Button
              onClick={() => {
                updateTaskStatus(params.row._id, true);
              }}
            >
              <DoneIcon style={{ color: "#000000" }} />
            </Button>
            <Button
              onClick={() => {
                updateTaskStatus(params.row._id, false);
              }}
            >
              <CancelIcon style={{ color: "#000000" }} />
            </Button>
          </div>
        );
      },
    },
  ];

  const ApprovedDataGridColumn = [
    {
      field: "internName",
      headerName: "Intern Name",
      width: 150,
    },
    {
      field: "taskname",
      headerName: "Task",
      width: 150,
    },
    {
      field: "reward",
      headerName: "Reward",
      width: 150,
    },
    {
      field: "image",
      headerName: "Image",
      width: 150,
      renderCell: (params: any) => {
        return (
          <Button
            onClick={() => {
              viewImage(params.row.image);
            }}
          >
            <ImageIcon style={{ color: "#000000" }} />
          </Button>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      width: 400,
    },
  ];

  const myInternColumn = [
    {
      field: "internName",
      headerName: "Intern Name",
      width: 150,
    },
    {
      field: "refferals",
      headerName: "Refferal Count",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params: any) => {
        return (
          <div
            className="rowitem"
            style={{ cursor: "pointer" }}
            onClick={() => {
              viewInternStatus(params.row._id);
            }}
          >
            {params.row.stipend} {"("}
            {(
              (params.row.stipend / params.row["ProgramInfo"][0]["target"]) *
              100
            ).toFixed(1)}{" "}
            % {")"}
          </div>
        );
      },
    },
  ];

  const viewInternStatus = (intern_id: any) => {
    AxiosInstance()
      .get(`/intern-panel/dashboard/${intern_id}`)
      .then(({ data }) => {
        setLatestActivity(data.latestactivities);
        setIntern(data.internData);

        let temp_intern_tasks: any = [];
        for (let index = 0; index < data.taskstatus.pending.length; index++) {
          temp_intern_tasks.push({
            _id: data.taskstatus.pending[index]._id,
            taskname: data.taskstatus.pending[index].taskname,
            reward: data.taskstatus.pending[index].reward,
            description: data.taskstatus.pending[index].description,
            status: "Pending",
          });
        }
        for (
          let index = 0;
          index < data.taskstatus.unverified.length;
          index++
        ) {
          temp_intern_tasks.push({
            _id: data.taskstatus.unverified[index]._id,
            taskname: data.taskstatus.unverified[index].taskname,
            reward: data.taskstatus.unverified[index].reward,
            description: data.taskstatus.unverified[index].description,
            status: "Unverified",
          });
        }
        for (let index = 0; index < data.taskstatus.verified.length; index++) {
          temp_intern_tasks.push({
            _id: data.taskstatus.verified[index]._id,
            taskname: data.taskstatus.verified[index].taskname,
            reward: data.taskstatus.verified[index].reward,
            description: data.taskstatus.verified[index].description,
            status: "Verified",
          });
        }
        setTasks(temp_intern_tasks);
        setProgramDetails(data.programDetails);
        set_selected_intern(intern_id);
        setDetailOpen(true);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const intern_task_status_column = [
    {
      field: "taskname",
      headerName: "Task",
      width: 150,
    },
    {
      field: "reward",
      headerName: "Reward",
      width: 150,
    },
    {
      field: "description",
      headerName: "Description",
      width: 400,
    },
    {
      field: "status",
      headerName: "Status",
      width: 400,
    },
  ];

  const addInternPopup = () => {
    AxiosInstance()
      .get("/intern-panel/programs")
      .then(({ data }) => {
        console.log(data);
        setPrograms(data.programs);
        setnew_intern_info({});
        setaddIntern(true);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const updateNewInternValue = (name: any, value: any) => {
    let temp = new_intern_info;
    temp[name] = value;
    setnew_intern_info(temp);
  };

  const createNewIntern = () => {
    if (
      new_intern_info["name"] &&
      new_intern_info["name"].length > 0 &&
      new_intern_info["number"] &&
      new_intern_info["number"].length == 10 &&
      new_intern_info["number"] &&
      new_intern_info["program_id"]
    ) {
      AxiosInstance()
        .post("/intern-panel/createIntern", new_intern_info)
        .then((data) => {
          toast("Intern Added");
          setRefresh(!refresh);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      toast("Invalid Info for Intern");
    }
  };

  const DeleteInternApi = () => {
    AxiosInstance()
      .delete(`/intern-panel/deleteIntern/${selected_intern}`)
      .then((data) => {
        toast("Intern Deleted");
        setRefresh(!refresh);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <div>
      <HeaderManager />
      <div
        style={{
          position: "absolute",
          width: "90%",
          marginTop: "8%",
          marginLeft: "5%",
          marginRight: "5%",
          verticalAlign: "top",
        }}
      >
        <div
          style={{
            display: "inline-block",
            width: "75%",
            verticalAlign: "top",
          }}
        >
          <Paper elevation={5} style={{ padding: 10 }}>
            <Tabs
              value={tabvalue}
              onChange={handleTabValueChange}
              centered
              style={{
                borderRadius: 16,
                height: 18,
                backgroundColor: "rgba(116, 116, 116, 1)",
              }}
              sx={{
                [`& .${tabsClasses.indicator}`]: {
                  background: "#FFFFFF",
                },
              }}
            >
              <Tab style={{ color: "#FFFFFF", fontSize: 12 }} label="Pending" />
              <Tab
                style={{ color: "#FFFFFF", fontSize: 12 }}
                label="Approved"
              />
              <Tab
                style={{ color: "#FFFFFF", fontSize: 12 }}
                label="Declined"
              />
            </Tabs>
            {tabvalue === 0 ? (
              <div style={{ margin: 10, height: "50vw" }}>
                <DataGrid
                  getRowId={(row: any) => row._id}
                  rows={pending_tasks}
                  columns={PendingDataGridColumn}
                />
              </div>
            ) : null}
            {tabvalue === 1 ? (
              <div style={{ margin: 10, height: "50vw" }}>
                <DataGrid
                  getRowId={(row: any) => row._id}
                  rows={approved_tasks}
                  columns={ApprovedDataGridColumn}
                />
              </div>
            ) : null}
            {tabvalue === 2 ? (
              <div style={{ margin: 10, height: "50vw" }}>
                <DataGrid
                  getRowId={(row: any) => row._id}
                  rows={declined_tasks}
                  columns={ApprovedDataGridColumn}
                />
              </div>
            ) : null}
          </Paper>
        </div>
        <div
          style={{
            display: "inline-block",
            width: "20%",
            verticalAlign: "top",
          }}
        >
          <Paper
            elevation={5}
            style={{
              padding: 2,
              height: "55vw",
              overflowY: "scroll",
              margin: 5,
              borderRadius: 16,
            }}
          >
            <div style={{ alignItems: "center" }}>
              <p style={{ margin: 10, fontWeight: 700 }}>My Interns</p>
              <Button
                onClick={addInternPopup}
                style={{
                  background: "#8439FF",
                  color: "#FFFFFF",
                  marginLeft: "50%",
                }}
              >
                Add Intern
              </Button>
            </div>
            <div style={{ margin: 10, height: "50vw" }}>
              <DataGrid
                getRowId={(row: any) => row._id}
                rows={taskRequests.intern}
                columns={myInternColumn}
              />
            </div>
          </Paper>
        </div>
      </div>
      <Modal
        open={openModel}
        onClose={() => {
          setOpenModal(false);
        }}
        style={{
          overflowX: "scroll",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "25%",
            width: "50%",
            background: "#FFFFFF",
            top: "10%",
            bottom: "10%",
          }}
        >
          <img src={imgsrc} />
        </div>
      </Modal>
      <Modal
        open={detailopen}
        onClose={() => {
          setDetailOpen(false);
        }}
        style={{ overflowY: "scroll" }}
      >
        <div style={{ margin: 75, overflowY: "scroll" }}>
          <Paper elevation={5} style={{ padding: 2, borderRadius: 16 }}>
            <div
              style={{
                marginLeft: 5,
                marginRight: 5,
                marginBottom: 5,
                marginTop: 30,
                height: "10%",
                textAlign: "center",
              }}
            >
              <div
                style={{ width: "23%", display: "inline-block", padding: 5 }}
              >
                <Paper
                  elevation={5}
                  style={{
                    background: "#F6F6F6",
                    height: "inherit",
                    borderRadius: 16,
                  }}
                >
                  <div style={{ padding: 4 }}>
                    <img
                      style={{ position: "relative", top: -22, left: 20 }}
                      src={TotalRef}
                    />
                    <p style={{ fontSize: 36, fontWeight: 700 }}>
                      {intern.refferals}
                    </p>
                    <p style={{ fontSize: 16, fontWeight: 500 }}>
                      Total Refferals
                    </p>
                    <p style={{ fontSize: 12, fontWeight: 400 }}>
                      10 {programDetails.paid ? <>{"\u20B9"}</> : <>Pts</>}
                      /College
                    </p>
                  </div>
                </Paper>
              </div>
              <div
                style={{ width: "23%", display: "inline-block", padding: 5 }}
              >
                <Paper
                  elevation={5}
                  style={{
                    background: "#F6F6F6",
                    height: "inherit",
                    borderRadius: 16,
                  }}
                >
                  <div style={{ padding: 4 }}>
                    <img
                      style={{ position: "relative", top: -22, left: 20 }}
                      src={CampusRef}
                    />
                    <p style={{ fontSize: 36, fontWeight: 700 }}>
                      {intern.campusRefferals}
                    </p>
                    <p style={{ fontSize: 16, fontWeight: 500 }}>
                      Campus Referrals
                    </p>
                    <p style={{ fontSize: 12, fontWeight: 400 }}>
                      15 {programDetails.paid ? <>{"\u20B9"}</> : <>Pts</>}
                      /College
                    </p>
                  </div>
                </Paper>
              </div>
              <div
                style={{
                  width: "23%",
                  display: "inline-block",
                  padding: 5,
                  verticalAlign: "top",
                }}
              >
                <Paper
                  elevation={5}
                  style={{
                    background: "#F6F6F6",
                    height: "inherit",
                    borderRadius: 16,
                  }}
                >
                  <div style={{ padding: 4 }}>
                    <img
                      style={{ position: "relative", top: -22, left: 20 }}
                      src={BeepMoments}
                    />
                    <p style={{ fontSize: 36, fontWeight: 700 }}>
                      {intern.beepmoments}
                    </p>
                    <p style={{ fontSize: 16, fontWeight: 500 }}>
                      User Posted Beeps
                    </p>
                    <p style={{ fontSize: 12, fontWeight: 400 }}>
                      10 {programDetails.paid ? <>{"\u20B9"}</> : <>Pts</>}
                      /FirstBeep
                    </p>
                  </div>
                </Paper>
              </div>
              <div
                style={{ width: "23%", display: "inline-block", padding: 5 }}
              >
                <Paper
                  elevation={5}
                  style={{
                    background: "#F6F6F6",
                    height: "inherit",
                    borderRadius: 16,
                  }}
                >
                  <div style={{ padding: 4 }}>
                    <img
                      style={{ position: "relative", top: -22, left: 20 }}
                      src={TotalEarn}
                    />
                    <p style={{ fontSize: 36, fontWeight: 700 }}>
                      {programDetails.paid ? <>{"\u20B9"}</> : null}{" "}
                      {intern.stipend}
                    </p>
                    <p style={{ fontSize: 16, fontWeight: 500 }}>
                      Total {programDetails.paid ? <>Amt</> : <>Pts</>}{" "}
                      Collected
                    </p>
                    <p style={{ fontSize: 12, fontWeight: 400 }}>
                      Target {programDetails.target}
                    </p>
                  </div>
                </Paper>
              </div>
              <div style={{ marginTop: 10 }}>
                <Button
                  onClick={DeleteInternApi}
                  style={{ background: "#8439FF", color: "#FFFFFF" }}
                >
                  Delete Intern
                </Button>
              </div>
            </div>
          </Paper>
          <Paper
            elevation={5}
            style={{ padding: 2, height: "20%", margin: 5, borderRadius: 16 }}
          >
            <p style={{ margin: 10, fontWeight: 700 }}>Latest Activities</p>
            <div style={{ margin: 10 }}>
              <div className={`flex px-2 py-2 `}>
                {" "}
                {/* row direction*/}
                <div>Description</div>
                <div className="mr-0 ml-auto">Reward</div>
              </div>
              {latestActivity.length ? (
                latestActivity.map((activity: any, index: number) => {
                  return (
                    <div
                      className={`flex px-2 py-2 ${
                        index !== 0 ? "border-t-2" : ""
                      }`}
                    >
                      {" "}
                      {/* row direction*/}
                      <div>
                        {index + 1}. {activity.activity}
                      </div>
                      <div className="mr-0 ml-auto">
                        {activity.reward}{" "}
                        {programDetails.paid ? <>{"\u20B9"}</> : <>Pts</>}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center">No Records</div>
              )}
            </div>
          </Paper>
          <Paper
            elevation={5}
            style={{ padding: 2, height: "35vw", margin: 5, borderRadius: 16 }}
          >
            <p style={{ margin: 10, fontWeight: 700 }}>Tasks</p>
            <div style={{ margin: 10, height: "30vw" }}>
              <DataGrid
                getRowId={(row: any) => row._id}
                rows={tasks}
                columns={intern_task_status_column}
              />
            </div>
          </Paper>
        </div>
      </Modal>
      <Modal
        open={addIntern}
        onClose={() => {
          setaddIntern(false);
        }}
        style={{ overflowY: "scroll" }}
      >
        <div
          style={{
            position: "absolute",
            left: "35%",
            width: "30%",
            top: "20%",
          }}
        >
          <Paper elevation={5} style={{ padding: 2, borderRadius: 16 }}>
            <div style={{ padding: 10 }}>
              <div style={{ marginTop: 10 }}>
                <TextField
                  required
                  id="outlined-required"
                  label="Intern Name"
                  onChange={(e) => {
                    updateNewInternValue("name", e.target.value);
                  }}
                  defaultValue={new_intern_info.name}
                  fullWidth
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <TextField
                  required
                  id="outlined-required"
                  label="Intern Number"
                  onChange={(e) => {
                    updateNewInternValue("number", e.target.value);
                  }}
                  defaultValue={new_intern_info.number}
                  fullWidth
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Program
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={new_intern_info.program_id}
                    onChange={(e) => {
                      updateNewInternValue("program_id", e.target.value);
                    }}
                    label="Program Name"
                  >
                    {programs.map((program: any) => {
                      console.log(program);
                      return (
                        <MenuItem value={program._id}>
                          {program.program_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div style={{ marginTop: 10 }}>
                <Button onClick={createNewIntern}>Create</Button>
              </div>
            </div>
          </Paper>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
}
