import { Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Components/Header';
import Navigation from '../Components/Navigation'
import PageHeading from '../Components/pageHeading';
import AxiosInstance from '../Constants/Config/AxiosInstance';

export default function Training(){

    const[courses,setCourses] = useState([])
    const [courses_completed,setCourses_Completed]:any= useState({})
    const navigate = useNavigate()

    useEffect(() => {
        AxiosInstance()
        .get('/intern-panel/trainings')
        .then(({data})=>{
          console.log(data)
          setCourses(data.courses)
          setCourses_Completed(data.courses_completed)
        })
      }, []);


    return(<div>
        <Header
            navid={2}
        />
        <div style={{ position: "absolute", width: "90%", marginTop: "8%", marginLeft: "5%", marginRight: "5%"}}>
            <Paper elevation={5} style={{padding:10}}>
            {
                courses.map((course:any)=>{
                    return(
                        <div className='shadow-md px-2 py-2 rounded bg-white' style={{display:"inline-block", width:"30%"}}>
                            <div><img src={course.image}/></div>
                            <div className='text-center my-2'>
                                <span className='text-md font-medium'>{course.moduleName}</span>
                               <span className='mr-0 ml-auto'> {
                                Object.keys(courses_completed).indexOf(course._id)===-1?
                                    (<React.Fragment>
                                        <button  className="bg-amber-400 px-2 py-1 rounded shadow-md hover:shadow mx-2" onClick={()=>{navigate(`/course/${course._id}`)}}> Start </button>
                                    </React.Fragment> ) :
                                        
                                    Object.keys(course.chapters).length === courses_completed[course._id].length?
                                        (<span className='bg-lime-400 rounded-full text-xs px-2 py-2'>Completed</span>):
                                        ( <span>
                                            <button  className="bg-amber-400 px-2 py-1 rounded shadow-md hover:shadow mx-2" onClick={()=>{navigate(`/course/${course._id}`)}}> Resume</button>
                                            <span className='bg-green-400 rounded-full text-xs px-2 py-2 ml-auto  mr-0'>In Progress</span>
                                         </span>)
                                }
                            </span>
                            </div>

                        </div>
                    )
                })
            }
            </Paper>
        </div>
    </div>)
}